function estilosMenu(atual) {
	/*remove ativo ultimo link*/
	$(".last-link").removeClass('last-link-ativo');
	/*cor link*/
	$(".link-geral").removeClass('link-geral-ativo');
	$(atual).addClass('link-geral-ativo');
	/*seta link*/
	if(!$(atual).next().is(":visible")) {
		$(".link-geral").next().slideUp(200);
		$(atual).next().slideDown(200);
	}
}

function estiloLastMenu(last) {
	/*remove ativo dos links gerais*/
	$(".link-geral").removeClass('link-geral-ativo');
	$(".link-geral").next().slideUp(200);
	/*ativo ultimo link*/
	$(last).addClass('last-link-ativo');
}

var flag = true;
function moveSite(href) {
	flag = false;
	var hash = href.split("#").pop(); //o split separa o index.html#inicio em um array com as duas partes, e o pop seleciona o último item do array.
    var altura = $("#section-"+hash).position().top;
    $("html, body").animate({scrollTop:altura}, 1200, function(){
    	flag = true;//depois de animar troca pra true
    });
}

$(document).ready(function() {
	/*plugin scroll*/
	$("html").niceScroll({scrollspeed:100, mousescrollstep:60, cursorwidth:'10px', cursorborder:'none', cursoropacitymin:1, zindex:9999}); 

	/*Descer página*/
	$(".fa-chevron-down").on("click", function() {
		window.location.hash = "#o-que-e";
	});

	/*plugin parallax scrolling*/
	if($(".corpo-menu-inicial:visible").length > 0) {
        var s = skrollr.init();
    }

	/*Clique nos links*/
	$(".link-menu").on("click", function(){
        moveSite($(this).attr("href"));
        /*mobile*/
        $(".engloba-menu-mobile").fadeOut();
		$(".fa-times").fadeOut();
        $(".fa-bars").fadeIn();
        aux = 0;
	});

	/*Sincronização menus*/
	$(".link-menu-inicial").on("click", function(){
		var position = $(this).data("pos");

		/*remove ativo ultimo link*/
		$(".last-link").removeClass('last-link-ativo');

		/*cor link*/
		$(".link-geral").removeClass('link-geral-ativo');
		$(".menu-geral").children().children().eq(position).children('.link-geral').addClass('link-geral-ativo');

		/*seta link*/
		if(!$(".menu-geral").children().children().eq(position).children('.link-geral').next().is(":visible")) {
			$(".link-geral").next().slideUp(200);
			$(".menu-geral").children().children().eq(position).children('.link-geral').next().slideDown(200);
		}
	});

	/*mask tel*/
    if (typeof $("input[type=tel]").mask == 'function') { 
        var options =  {onKeyPress: function(cep){
            var mask;
            var e = cep.replace(/\D/g, "");
            if (e.length > 10) {
                mask = "(00)00000-0000";
            } else {
                mask = "(00)0000-00000";
            }
            $("input[type=tel]").mask(mask, this);
        }};
        $("input[type=tel]").mask('(00)0000-00000',options);
    }

	/*Validação form*/

    function limparMensagens() {
        $(".warning").hide();
    }

    if($(".valida-form").length > 0) {
        $(".valida-form").WP8FormValidation({
            onInputError: function(obj) {
                obj.addClass("marca-erro");
            },
            onInputOk: function(obj) { 
                obj.removeClass("marca-erro");         
            },
            onFormSubmitError: function(mensagem) {  
                limparMensagens();
                $(".error-validate").slideDown(200);
            }
        });
    } 

    $('.form-ajax').on('submit', function(event) {
        event.preventDefault();

        limparMensagens();

        var form  = $(this);
        var botao = form.find('button[type=submit]');

        botao.text('Enviando...').attr('disabled', true).css('cursor', 'default');

        $.ajax({
            url: form.attr('action'),
            type: 'POST',
            dataType: 'json',
            data: form.serialize(),
        })
        .done(function() {
            $(form).trigger('reset');
            $(".ok").slideDown(200);
        })
        .fail(function(data) {
            var erros = data.responseJSON;
            var errosString = erros[Object.keys(erros)[0]];

            $('.error-ajax').html(errosString);
            $('.error-ajax').slideDown(200);
        })
        .always(function() {
            botao.text('Enviar').attr('disabled', false).css('cursor', 'pointer');
        });
        
    });

    /*Carrossel exemplos*/
    var banner = {
        n_itens: $(".img-carousel").length,
        pos: 1,

        init: function() {
            $(".left-arrow").on("click",function() {
                banner.esquerda();
            });
            $(".right-arrow").on("click",function() {
                banner.direita();
            });
        },

        esquerda: function() {
            if(banner.pos > 0) {
                banner.pos--; 

                banner.doIt();
                $(".img-carousel").eq(banner.pos).next().addClass('img-dir-carousel');
                $(".img-carousel").eq(banner.pos).addClass('img-main-carousel');
                $(".img-carousel").eq(banner.pos).next().next().addClass('img-some');
            }
        },

        direita: function() {
            if(banner.pos < banner.n_itens - 1) {
                banner.pos++;
                
                banner.doIt();
                $(".img-carousel").eq(banner.pos).prev().addClass('img-esq-carousel');
                $(".img-carousel").eq(banner.pos).addClass('img-main-carousel');  
                $(".img-carousel").eq(banner.pos).prev().prev().addClass('img-some');
            }
        },

        doIt: function() {
            $(".img-carousel").removeClass('img-some');
            $(".img-carousel").removeClass('img-main-carousel');
            $(".img-carousel").eq(banner.pos).removeClass('img-dir-carousel');
        }
    };
    banner.init();

    /*Carrossel Mobile*/
    bannerMob = {
        n_itens: $(".img-exemplo-mob").length,
        pos: 0,
        tam_cada_img: 0,

        init: function() {
            bannerMob.tam_cada_img = $(".img-exemplo-mob").innerWidth() + 16;
            var tam_slider = bannerMob.tam_cada_img * $(".img-exemplo-mob").length;
            $(".slider-mobile").innerWidth(tam_slider);

            $(".arrow-esq-mob").on("click",function() {
                bannerMob.esquerda();
            });
            $(".arrow-dir-mob").on("click",function() {
                bannerMob.direita();
            });

            $(".img-exemplo-mob").on("swipeleft", function() {  
                bannerMob.direita(); 
            });   
            $(".img-exemplo-mob").on("swiperight", function() {
                bannerMob.esquerda();     
            }); 
        },

        esquerda: function() {
            if(bannerMob.pos > 0) {
                bannerMob.pos--; 
                bannerMob.move(bannerMob.pos); 
            }
        },

        direita: function() {
            if(bannerMob.pos < bannerMob.n_itens - 1) {
                bannerMob.pos++;
                bannerMob.move(bannerMob.pos);
            }
        },

        move: function() {
            $(".slider-mobile").stop().animate({"left":- bannerMob.pos * bannerMob.tam_cada_img},400);
        }
    }
    bannerMob.init();

    /*abre e fecha fancybox*/
    $(".img-carousel").on("click", function() {
    	if ($(this).hasClass('img-main-carousel')) {
    		var pos = $(this).index();

			$(".img-ampliada").eq(pos).fadeIn();
	    	$(".pai-img-ampliada").fadeIn();
	    	
    	}
    });
    $(".close").on("click", function() {
    	$(".img-ampliada").fadeOut();
    	$(".pai-img-ampliada").fadeOut();
    });

    /*abre e fecha fancybox mobile*/
    $(".img-exemplo-mob").on("click", function() {
        var pos = $(this).index();
        $(".img-ampliada").eq(pos).fadeIn();
        $(".pai-img-ampliada").fadeIn();
    });

    /*menu mobile*/
    var aux = 0;
    $(".sanduiche").on("click", function() {
        if (aux == 0) {
            $(".sanduiche").fadeOut();
            $(".fa-times").fadeIn();
            $(".engloba-menu-mobile").fadeIn();
            aux = 1;
        }else {
            $(".sanduiche").fadeOut();
            $(".fa-bars").fadeIn();
            $(".engloba-menu-mobile").fadeOut();
            aux = 0;
        }
    });

    $(".flag-focus").on("focus", function(){
        $(this).addClass('focus');
    });
    $(".flag-focus").on("blur", function(){
        $(".flag-focus").removeClass('focus');
    });
});

$(window).on("load", function() {//qdo já passa o hash no link já desloca a página para a posição
	if (window.location.hash != "#inicio" && window.location.hash != "") {//se for com a hash inicio ou vazio não chama a função para deslocamento
		moveSite(window.location.hash);
	}; 
});

/*impede scroll qdo está se deslocando pela função moveSite*/
$('body').on({
    'mousewheel': function(e) {
        if (!flag){//qdo está se deslocando pela função moveSite 
        	e.preventDefault();
        	e.stopPropagation();
        }
    }
});

$(window).on("load scroll resize", function() {
	var scrollY = window.pageYOffset;

	/*menu geral fixed*/
    var topWhat = $(".o-que-e").position().top;
    if(scrollY >= topWhat) {
    	$(".engloba-menu-geral").addClass('engloba-menu-geral-fixed');
    }else {
    	$(".engloba-menu-geral").removeClass('engloba-menu-geral-fixed');
    }

    /*ajustando hash e ativos com o scroll do mouse*/
    var topHow = $(".como-funciona").position().top;
    var topInvest = $(".investimento").position().top;
    var topWant = $(".quero-meu-site").position().top;
    
    if(scrollY > 0 && scrollY < topWhat) {
		var hash = $(".menu-geral").children().children().eq(0).children('.link-geral').attr("href");
		window.location.hash = hash.split("#").pop();

        /*mobile*/
        if($(".pai-menu-mobile:visible").length > 0) {//se tiver visible é mobile
            $(".link-mobile").removeClass('link-mobile-ativo');
            $(".menu-mobile").children().children().eq(0).children('.link-mobile').addClass('link-mobile-ativo');
        } 
    }
    else if (scrollY >= topWhat && scrollY < topHow) {
		var hash = $(".menu-geral").children().children().eq(1).children('.link-geral').attr("href");
		window.location.hash = hash.split("#").pop();
    	
        if($(".menu-inicial:visible").length > 0) {//se tiver visible é desktop
            estilosMenu($(".menu-geral").children().children().eq(1).children('.link-geral'));
        }

        /*mobile*/
        if($(".pai-menu-mobile:visible").length > 0) {
            $(".link-mobile").removeClass('link-mobile-ativo');
            $(".menu-mobile").children().children().eq(1).children('.link-mobile').addClass('link-mobile-ativo');
        }
    }
    else if (scrollY >= topHow && scrollY < topInvest) {
		var hash = $(".menu-geral").children().children().eq(2).children('.link-geral').attr("href");
		window.location.hash = hash.split("#").pop();

        if($(".menu-inicial:visible").length > 0) {
            estilosMenu($(".menu-geral").children().children().eq(2).children('.link-geral'));
        }

        /*mobile*/
        if($(".pai-menu-mobile:visible").length > 0) {
            $(".link-mobile").removeClass('link-mobile-ativo');
            $(".menu-mobile").children().children().eq(2).children('.link-mobile').addClass('link-mobile-ativo');
        }
    }
    else if (scrollY >= topInvest && scrollY < topWant) {
		var hash = $(".menu-geral").children().children().eq(3).children('.link-geral').attr("href");
		window.location.hash = hash.split("#").pop();

        if($(".menu-inicial:visible").length > 0) {
            estilosMenu($(".menu-geral").children().children().eq(3).children('.link-geral'));
        }

        /*mobile*/
        if($(".pai-menu-mobile:visible").length > 0) {
            $(".link-mobile").removeClass('link-mobile-ativo');
            $(".menu-mobile").children().children().eq(3).children('.link-mobile').addClass('link-mobile-ativo');
        }
    }
    else if (scrollY >= topWant) {
		var hash = $(".menu-geral").children().children().eq(4).children('.last-link').attr("href");
		window.location.hash = hash.split("#").pop(); 

        if($(".menu-inicial:visible").length > 0) {
            estiloLastMenu($(".menu-geral").children().children().eq(4).children('.last-link'));
        }
    	
        /*mobile*/
        if($(".pai-menu-mobile:visible").length > 0) {
            $(".link-mobile").removeClass('link-mobile-ativo');
            $(".menu-mobile").children().children().eq(4).children('.link-mobile').addClass('link-mobile-ativo');
        }
    }

    /*animação aparecer como funciona*/
    if ($(".corpo-menu-inicial:visible").length > 0) {//se for desktop faz
        var posicao = $(".como-funciona-pt01").position().top - window.innerHeight;
        if (scrollY > (posicao + 150) && !$(".ccf01").hasClass('aparece')) {
            setTimeout(function() { 
                $(".ccf01").addClass('aparece');
            }, 500);
            setTimeout(function() { 
                $(".ccf02").addClass('aparece');
            }, 750);
            setTimeout(function() { 
                $(".ccf03").addClass('aparece');
            }, 1000);
        }
    };
});